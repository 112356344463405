/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * Vendor-prefixed and regular ::selection selectors cannot be combined:
 * https://stackoverflow.com/a/16982510/7133471
 *
 * Customize the background color to match your design.
 */

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
  resize: vertical;
}

/* ==========================================================================
   AASHQ's custom styles
   ========================================================================== */

// Global

h3 {
  font-size: 24px;
  font-weight: inherit;
}

ol {
  padding-left: 1em;
}

p {
  margin-bottom: 0;
}

.alignright {
  float: right;
  margin-left: 1rem;
}

.alignleft {
  float: left;
  margin-right: 1rem;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.alignwide {
  margin-left: -80px;
  margin-right: -80px;
}

.alignfull {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  max-width: 100vw;
  width: 100vw;
}

.alignfull img {
  width: 100vw;
}

.contact-btn {
  display: inline-block;
  background-color: #ff893f;
  color: #fff;
  padding: 13px 29px;

  &:hover {
    color: #fff;
  }
}

.icon-tooltip {
  background-color: #ffdb34;
  position: absolute;
  top: -80%;
  left: 55%;
  width: 250px;
  height: fit-content;
  border-radius: 50%;
  display: flex;
  align-items: center;
  padding: 35px;
  justify-content: center;
  font-size: 80%;
  z-index: 1;
  box-shadow: 10px 10px 62px -25px rgba(0, 0, 0, 0.75);

  &:after {
    content: "";
    z-index: -1;
    position: absolute;
    left: -12%;
    top: 75%;
    right: 55%;
    margin: 0 auto;
    width: 0;
    height: 0;
    transform: rotate(40deg);
    border-top: 70px solid #ffdb34;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    box-shadow: 10px 10px 62px -25px rgba(0, 0, 0, 0.75);
  }
}

.nf-form-fields-required {
  display: none;
}

.nf-form-content {
  .nf-field-label {
    label {
      font-weight: 300;
    }
  }

  .nf-element[type="text"],
  .nf-element[type="email"],
  textarea {
    // Ninja Forms override
    border-radius: 6px !important;
    border-color: #e2e2e2 !important;
    color: #9ea0a4 !important;

    &:hover {
      border-color: red !important;
    }
  }

  .nf-element[type="button"] {
    border-radius: 4px;
    background-color: #ff893f;
    padding: 12px 24px;

    &:hover {
      color: white;
      background-color: red;
    }
  }
}

.insta-gallery-actions {
  display: none;
}

strong,
b {
  font-weight: 700;
}

// Per template
.header--overlay {
  background: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(255,255,255,0) 100%);
  width: 100%;
  height: 200px;
  position: absolute;
  display: block;
  z-index: 1;
}
[data-template="header"] {
  z-index: 1;
  margin-top: 40px;
  min-height: 550px;

  .logo {
    max-height: 150px;

    &--ruijter {
      image {
        filter: drop-shadow(3px 1px 3px #FFF);
      }
      filter: drop-shadow(0px 0px 20px #000);
      -webkit-filter: drop-shadow(0px 0px 20px #000);
      &__bg{
        width: 100%;
      }
    }
  }

  .mobile-toggle-wrapper {
    display: none;
  }

  nav {
    width: 75%;

    > ul {
      > .menu-item {
        a {
          display: inline-block;
          text-align: center;
          width: 100%;
          padding: 5px 15px;
          color: #fff;
          font-weight: 400;
          text-decoration: none;
          text-transform: uppercase;
          transition: all 0.3s cubic-bezier(0.42, 0.01, 0.58, 1);
        }

        > a {
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        }

        > .sub-menu {
          max-height: 0;
          opacity: 0;
          overflow: hidden;
          transition: all 0.3s cubic-bezier(0.42, 0.01, 0.58, 1);
          padding: 0;

          > .menu-item {
            border-top: 1px solid lightgray;

            &:last-of-type {
              > a {
                border-radius: 0 0 3px 3px;
              }
            }

            &::marker {
              content: none;
            }
          }
        }

        &:not(.menu-item-has-children) {
          a {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
          }
        }

        &.current_page_item {
          > a {
            background-color: #fff;
          }
        }

        &:hover {
          a {
            background-color: #fff;
          }

          > .sub-menu {
            max-height: 500px;
            opacity: 1;
          }
        }
      }
    }
  }

  .optional-title {
    flex-basis: 80%;
    font-size: 55px;
    line-height: 1.2;
    color: #fff;
    text-shadow: 1px 1px #505050;
    font-weight: 700;
  }
}

[data-template="footer"] {
  padding: 40px;
  color: #fff;
  background-color: var(--footer-bg-color);
  background-image: var(--footer-bg-image);
  background-position: center center;
  background-repeat: repeat;

  .site-title {
    font-size: 18px;
  }

  .contact-info {
    > div {
      flex-basis: 25%;

      &.social {
        font-size: 24px;

        p {
          margin: 0 15px;
        }
      }

      &.brands {
        img {
          height: 125px;
          width: auto;
        }
      }
    }
  }

  .legal {
    li {
      &:not(:first-of-type) {
        padding-left: 1em;
        text-indent: -1em;

        &:before {
          content: "|";
          padding-right: 5px;
        }
      }

      &:not(:last-of-type) {
        margin-right: 5px;
      }
    }
  }

  a {
    color: #fff;
  }
}

/* ==========================================================================
   Helper classes
   ========================================================================== */

/*
 * Hide visually and from screen readers
 */

.hidden,
[hidden] {
  display: none !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  /* 1 */
}

/*
 * Extends the .sr-only class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.sr-only.focusable:active,
.sr-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */

.invisible {
  visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.clearfix::before,
.clearfix::after {
  content: " ";
  display: table;
}

.clearfix::after {
  clear: both;
}

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */

@media only screen and (min-width: 35em) {
  /* Style adjustments for viewports that meet the condition */
}

@media print,
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-resolution: 1.25dppx),
  (min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */
}

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   https://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {
  *,
  *::before,
  *::after {
    background: #fff !important;
    color: #000 !important;
    /* Black prints faster */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: " (" attr(href) ")";
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
   * Printing Tables:
   * https://web.archive.org/web/20180815150934/http://css-discuss.incutio.com/wiki/Printing_Tables
   */
  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}
